import React, { useState } from "react";
import {
    Pane,
    Pill,
    Text,
    Icon,
    Strong,
    Heading,
    Card,
    majorScale,
    Link,
    Image,
    // Layer,
    Overlay,
} from "evergreen-ui";
import {
    // faGraduationCap,
    // faLocation,
    // faLocationDot,
    // faLocationPin,
    // faMapLocation,
    faMapLocationDot,
    // faMapMarker,
    // faMapPin,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { VscLocation } from "react-icons/vsc";
import {
    FaBriefcase,
    // FaGraduationCap,
    // FaLocationArrow,
    // FaMapMarker,
    // FaMapPin,
} from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import "../../static/ProjectList.css"; // Import a CSS file for styling
import "../../static/CertList.css";
import { Gallery } from "react-grid-gallery";
import { certs as CERTS } from "./certs";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ListSubheader from "@mui/material/ListSubheader";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { BiBorderRadius } from "react-icons/bi";

const certs = CERTS.map((cert) => ({
    ...cert,
    customOverlay: (
        <div className="custom-overlay__caption">
            <div>{cert.caption}</div>
            {cert.tags &&
                cert.tags.map((t, index) => (
                    <div key={index} className="custom-overlay__tag">
                        {t.title}
                    </div>
                ))}
        </div>
    ),
}));

const projects = [
    {
        name: "Project 1",
        description: "Description of Project 1",
        imageUrl: "project1.jpg",
        compositeImageUrl: require("../../static/macbookTemplate/favpng_apple-macbook-pro-macbook-air-macintosh-laptop.png"),
        websiteUrl: "https://project1.com",
    },
    {
        name: "Project 2",
        description: "Description of Project 1",
        imageUrl: "project1.jpg",
        compositeImageUrl: require("../../static/macbookTemplate/apple-macbook-pro-6306821_640.png"),
        websiteUrl: "https://project1.com",
    },
    {
        name: "Project 3",
        description: "Description of Project 1",
        imageUrl: "project1.jpg",
        compositeImageUrl: require("../../static/macbookTemplate/apple-macbook-pro-6306821_640.png"),
        websiteUrl: "https://project1.com",
    },
    {
        name: "Project 4",
        description: "Description of Project 1",
        imageUrl: "project1.jpg",
        compositeImageUrl: require("../../static/macbookTemplate/apple-macbook-pro-6306821_640.png"),
        websiteUrl: "https://project1.com",
    },
    {
        name: "Project 5",
        description: "Description of Project 1",
        imageUrl: "project1.jpg",
        compositeImageUrl: require("../../static/macbookTemplate/apple-macbook-pro-6306821_640.png"),
        websiteUrl: "https://project1.com",
    },
];

const CertificateList = () => {
    // return (
    //     <div style={{ display: "flex", flexWrap: "wrap" }}>
    //         {projects.map((project) => (
    //             <Card
    //                 key={project.name}
    //                 elevation={1}
    //                 width={300}
    //                 height={200}
    //                 margin={majorScale(2)}
    //                 position="relative"
    //                 cursor="pointer"
    //             >
    //                 <div
    //                     style={{
    //                         backgroundImage: `url(${project.compositeImageUrl})`,
    //                         backgroundSize: "cover",
    //                         backgroundPosition: "center",
    //                         width: "100%",
    //                         height: "100%",
    //                     }}
    //                 />
    //                 <Pane
    //                     position="absolute"
    //                     bottom={0}
    //                     width="100%"
    //                     backgroundColor="rgba(0, 0, 0, 0.6)"
    //                     padding={majorScale(1)}
    //                     textAlign="center"
    //                 >
    //                     <Pane marginBottom={majorScale(1)}>
    //                         <Text
    //                             color="white"
    //                             fontSize={16}
    //                             fontWeight={500}
    //                         >
    //                             {project.name}
    //                         </Text>
    //                     </Pane>
    //                     <Pane marginBottom={majorScale(1)}>
    //                         <Text color="white" fontSize={12}>
    //                             {project.description}
    //                         </Text>
    //                     </Pane>
    //                     <a
    //                         href={project.websiteUrl}
    //                         target="_blank"
    //                         rel="noopener noreferrer"
    //                         style={{ color: "white" }}
    //                     >
    //                         Visit Website
    //                     </a>
    //                 </Pane>
    //             </Card>
    //         ))}
    //     </div>
    // );
    const itemData = [
        {
            img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
            title: "Breakfast",
            author: "@bkristastucchio",
            rows: 4,
            cols: 4,
            featured: true,
        },
        {
            img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
            title: "Burger",
            author: "@rollelflex_graphy726",
        },
        {
            img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
            title: "Camera",
            author: "@helloimnik",
        },
        {
            img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
            title: "Coffee",
            author: "@nolanissac",
            cols: 4,
        },
        {
            img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
            title: "Hats",
            author: "@hjrc33",
            cols: 4,
        },
        {
            img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
            title: "Honey",
            author: "@arwinneil",
            rows: 4,
            cols: 4,
            featured: true,
        },
        {
            img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
            title: "Basketball",
            author: "@tjdragotta",
        },
        {
            img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
            title: "Fern",
            author: "@katie_wasserman",
        },
        {
            img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
            title: "Mushrooms",
            author: "@silverdalex",
            rows: 4,
            cols: 4,
        },
        {
            img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
            title: "Tomato basil",
            author: "@shelleypauls",
        },
        {
            img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
            title: "Sea star",
            author: "@peterlaster",
        },
        {
            img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
            title: "Bike",
            author: "@southside_customs",
            cols: 4,
        },
    ];

    const images = [
        {
            src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
            width: 320,
            height: 174,
            customOverlay: (
                <div className="custom-overlay__caption">
                    <div>Boats (Jeshu John - designerspics.com)</div>
                </div>
            ),
            // isSelected: true,
            // caption: "After Rain (Jeshu John - designerspics.com)",
        },
        {
            src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
            width: 320,
            height: 212,
            customOverlay: (
                <div className="custom-overlay__caption">
                    <div>Boats (Jeshu John - designerspics.com)</div>
                </div>
            ),
            // tags: [
            //     { value: "Ocean", title: "Ocean" },
            //     { value: "People", title: "People" },
            // ],
            // alt: "Boats (Jeshu John - designerspics.com)",
        },
        {
            src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
            width: 320,
            height: 212,
            customOverlay: (
                <div className="custom-overlay__caption">
                    <div>Boats (Jeshu John - designerspics.com)</div>
                </div>
            ),
        },
    ];

    // <Gallery images={images} />;
    const [hoveredProject, setHoveredProject] = useState(null);
    return (
        <Pane
            width="80%"
            alignItems="center"
            paddingX={16}
            // paddingY={8}
            margin="auto"
        >
            <Pane
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginTop={33}
            >
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    // borderBottom="1px solid #e8e8e8"
                    padding={8}
                    marginBottom={20}
                    position="relative"
                >
                    <Heading size={700} marginTop={5} color="#3279CB">
                        List of Certifications
                    </Heading>
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            width: "15%",
                            height: "33%",
                            borderBottom: "1px solid #3366FF",
                            borderRight: "1px solid #3279CB",
                        }}
                    />
                </Pane>
            </Pane>
            <Pane>
                <Gallery
                    images={certs}
                    enableImageSelection={false}
                    // thumbnailStyle={{
                    //     width: "100%",
                    //     height: "100%",
                    //     borderTopRightRadius: 5,
                    //     borderTopLeftRadius: 5,
                    //     borderBottomRightRadius: 5,
                    //     borderBottomLeftRadius: 5,
                    // }}
                />
            </Pane>
        </Pane>
    );
};

export default CertificateList;

// <div className="project-grid">
//     {projects.map((project, index) => (
//         <div className="project-item" key={index}>
//             <div className="project-image">
//                 <img
//                     src={project.compositeImageUrl}
//                     alt={project.name}
//                 />
//                 <div className="project-info">
//                     <h3>{project.name}</h3>
//                     <p>{project.description}</p>
//                     <a
//                         href={project.websiteUrl}
//                         target="_blank"
//                         rel="noopener noreferrer"
//                     >
//                         Visit Website
//                     </a>
//                 </div>
//             </div>
//         </div>
//     ))}
// </div>

// <ImageList sx={{ width: "100%", height: "100%" }}>
// <ImageListItem key="Subheader" cols={5}>
//     {/* <ListSubheader component="div">December</ListSubheader> */}
// </ImageListItem>
// {itemData.map((item) => (
//     <ImageListItem key={item.img}>
//         <img
//             srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
//             src={`${item.img}?w=248&fit=crop&auto=format`}
//             alt={item.title}
//             loading="lazy"
//         />
//         <ImageListItemBar
//             title={item.title}
//             subtitle={item.author}
//             actionIcon={
//                 <IconButton
//                     sx={{ color: "rgba(255, 255, 255, 0.54)" }}
//                     aria-label={`info about ${item.title}`}
//                     onClick={() => console.log("clicked")}
//                 >
//                     <a href={item.title}>Visit Website</a>
//                     <InfoIcon />
//                 </IconButton>
//             }
//         />
//     </ImageListItem>
// ))}
// </ImageList>
