import { Pane, Pill, Text, Icon, Strong, Heading } from "evergreen-ui";
import {
    // faGraduationCap,
    // faLocation,
    // faLocationDot,
    // faLocationPin,
    // faMapLocation,
    faMapLocationDot,
    // faMapMarker,
    // faMapPin,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { VscLocation } from "react-icons/vsc";
import {
    FaBriefcase,
    // FaGraduationCap,
    // FaLocationArrow,
    // FaMapMarker,
    // FaMapPin,
} from "react-icons/fa";
// import { Small } from "evergreen-ui/types";

const JobsList = () => {
    const educationItems = [
        {
            job: "Research Associate & Teaching Assistant",
            employee: "Harokopio University of Athens",
            department:
                "School of Digital Technology | Dept. of Informatics and Telematics",
            city: "ATHENS",
            role: "",
            start_year: "02/2014",
            end_year: "Present",
        },
        {
            job: "DevOps Engineer",
            employee: "CITYFAB Greece",
            department: "",
            city: "ATHENS",
            role: "",
            start_year: "07/2015",
            end_year: "05/2016",
        },
        {
            job: "Mobile Developer",
            employee: "Hyperon",
            department: "",
            city: "ATHENS",
            role: "Citius App development",
            start_year: "01/2016",
            end_year: "08/2016",
        },
        {
            job: "Technical Supervisor",
            employee: "IT training centers Technoplus",
            department: "",
            city: "P.FALIRO",
            role: "Technical Supervisor, Network Administrator",
            start_year: "09/2005",
            end_year: "08/2013",
        },
        {
            job: "IT Instructor",
            employee: "IT training centers",
            department: "",
            city: "ATHENS",
            role: "CompTIA, Microsoft (MTA, MCAS, MOS), Adobe Certifications Instructor",
            start_year: "09/2005",
            end_year: "08/2013",
        },
        {
            job: "Integrated Systems Engineer",
            employee: "Singular Logic | Singular Logic Integrator",
            department: "",
            city: "ATHENS",
            role: "",
            start_year: "12/2006",
            end_year: "06/2007",
        },
        {
            job: "Technical Assistant",
            employee: "Ministry of Development | G.S. of Industry Program",
            department: "",
            city: "ATHENS",
            role: "Responsible for SYK of East Attica and Athens",
            start_year: "05/2006",
            end_year: "12/2007",
        },
    ];

    return (
        <Pane
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop={33}
        >
            <Pane
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                // borderBottom="1px solid #e8e8e8"
                padding={8}
                marginBottom={20}
                position="relative"
            >
                {/* <Text size={500} fontWeight={600}> */}
                <Heading size={700} marginTop={5} color="#3279CB">
                    Jobs & Experience
                </Heading>
                {/* </Text> */}
                <div
                    style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        width: "15%",
                        height: "33%",
                        borderBottom: "1px solid #3366FF",
                        borderRight: "1px solid #3279CB",
                    }}
                />
            </Pane>
            {educationItems.map((item, index) => (
                <Pane
                    key={index}
                    display="flex"
                    alignItems="center"
                    // marginBottom={8}
                    marginBottom={16}
                >
                    <Pane display="flex" flexDirection="column">
                        <Pill
                            width={65}
                            height={67}
                            borderRadius={32}
                            marginRight={16}
                            borderTopLeftRadius={32}
                            borderTopRightRadius={32}
                            borderBottomLeftRadius={0}
                            borderBottomRightRadius={0}
                            background="#2c4877"
                            // background="#263F68"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            paddingX={20}
                        >
                            {
                                <Pane
                                    borderRadius="50%"
                                    width={40}
                                    height={40}
                                    // background="rgba(0, 0, 0, 0.13)"
                                    // background="#263F68"
                                    background="#3279CB"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Icon
                                        icon={FaBriefcase}
                                        color="rgba(255, 255, 255, 1.00)"
                                        size={18}
                                    />
                                </Pane>
                            }
                        </Pill>
                        <Pane
                            borderRadius="50%"
                            width={65}
                            height={1}
                            background="#2c4877"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Pane
                                height={1}
                                width="84%"
                                background="White"
                                marginY={0}
                            />
                        </Pane>
                        <Pill
                            width={65}
                            height={77}
                            borderRadius={32}
                            marginRight={16}
                            borderBottomLeftRadius={32}
                            borderBottomRightRadius={32}
                            borderTopLeftRadius={0}
                            borderTopRightRadius={0}
                            // background="#263F68"
                            background="#2c4877"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            paddingX={20}
                        >
                            {
                                <Text
                                    color="white"
                                    fontSize={12}
                                    // paddingX="50px"
                                >
                                    {item.end_year}
                                </Text>
                            }
                            <Pane
                                height={1}
                                width="84%"
                                borderBottom="1px dashed #e8e8e8"
                                marginY={1}
                            />
                            {
                                <Text color="white" fontSize={12}>
                                    {item.start_year}
                                </Text>
                            }
                        </Pill>
                    </Pane>

                    <Pane
                        width={540}
                        height={145}
                        display="flex"
                        justifyContent="space-between"
                        padding={8}
                        background="white"
                        borderRadius={5}
                        elevation={1}
                    >
                        <Pane
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                        >
                            <Strong
                                size={400}
                                fontSize={14}
                                marginTop={5}
                                color="gray800"
                            >
                                {item.job}
                            </Strong>
                            <Strong
                                size={400}
                                fontSize={14}
                                marginTop={5}
                                color="navy"
                            >
                                {item.employee}
                            </Strong>
                            {/* <Text
                                size={400}
                                fontSize={12}
                                marginTop={5}
                                color="gray800"
                            >
                                {item.department}
                            </Text> */}
                            {item.department && (
                                <Text
                                    size={400}
                                    fontSize={12}
                                    marginTop={5}
                                    color="gray800"
                                >
                                    {item.department}
                                </Text>
                            )}
                            {/* <Text
                                size={400}
                                fontSize={12}
                                marginTop={5}
                                color="gray800"
                            >
                                {item.role}
                            </Text> */}
                            {item.role && (
                                <Text
                                    size={400}
                                    fontSize={12}
                                    marginTop={5}
                                    color="gray800"
                                >
                                    {item.role}
                                </Text>
                            )}
                        </Pane>

                        <Pane display="flex" alignItems="flex-start">
                            <Icon
                                icon={
                                    <FontAwesomeIcon icon={faMapLocationDot} />
                                    // <FontAwesomeIcon icon={faLocationDot} />
                                } //VscLocation
                                size={20}
                                // color="#727272"
                                color="#3279CB"
                                paddingRight={2}
                                marginTop={5}
                            />
                            <Text
                                marginLeft={2}
                                marginRight={5}
                                marginTop={5}
                                color="#3279CB"
                                fontSize={11}
                            >
                                {item.city}
                            </Text>
                        </Pane>
                    </Pane>
                </Pane>
            ))}
        </Pane>
    );
};

export default JobsList;
