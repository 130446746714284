import React, { useState } from "react";
import { TextInputField, Textarea, Select, Button, Pane } from "evergreen-ui";
import styles from "./AddPublication.module.css";

const PublicationForm = () => {
    const [title, setTitle] = useState("");
    const [authors, setAuthors] = useState("");
    const [publicationType, setPublicationType] = useState("");
    const [publicationYear, setPublicationYear] = useState("");
    const [journalName, setJournalName] = useState("");
    const [abstract, setAbstract] = useState("");
    const [publicationUrl, setPublicationUrl] = useState("");
    const [latexCitation, setLatexCitation] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here, e.g., storing data to a database

        // Reset form fields after submission
        setTitle("");
        setAuthors("");
        setPublicationType("");
        setPublicationYear("");
        setJournalName("");
        setAbstract("");
        setPublicationUrl("");
        setLatexCitation("");
    };

    return (
        <div className={styles.formfield}>
            <Pane width="85%" margin="auto" className="form-container">
                <form onSubmit={handleSubmit}>
                    <Pane marginBottom={16} className={styles.formfield}>
                        <TextInputField
                            label="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </Pane>

                    <Pane marginBottom={16} className={styles.formfield}>
                        <TextInputField
                            label="Authors"
                            value={authors}
                            onChange={(e) => setAuthors(e.target.value)}
                            required
                        />
                    </Pane>

                    <Pane marginBottom={16} className={styles.formfield}>
                        <Select
                            label="Publication Type"
                            value={publicationType}
                            onChange={(e) => setPublicationType(e.target.value)}
                            required
                        >
                            <option value="international conference">
                                International Conference
                            </option>
                            <option value="international journal">
                                International Journal
                            </option>
                            <option value="book chapter">Book Chapter</option>
                            <option value="thesis">Thesis</option>
                            <option value="presentation">Presentation</option>
                        </Select>
                    </Pane>

                    <Pane marginBottom={16} className={styles.formfield}>
                        <TextInputField
                            label="Publication Year"
                            value={publicationYear}
                            onChange={(e) => setPublicationYear(e.target.value)}
                            required
                            type="number"
                        />
                    </Pane>

                    <Pane marginBottom={16} className={styles.formfield}>
                        <TextInputField
                            label="Journal/Conference Name"
                            value={journalName}
                            onChange={(e) => setJournalName(e.target.value)}
                        />
                    </Pane>

                    <Pane marginBottom={16} className={styles.formfield}>
                        <Textarea
                            label="Abstract"
                            value={abstract}
                            onChange={(e) => setAbstract(e.target.value)}
                        />
                    </Pane>

                    <Pane marginBottom={16} className={styles.formfield}>
                        <TextInputField
                            label="Publication URL"
                            value={publicationUrl}
                            onChange={(e) => setPublicationUrl(e.target.value)}
                            type="url"
                        />
                    </Pane>

                    <Pane marginBottom={16} className={styles.formfield}>
                        <Textarea
                            label="Latex Citation"
                            value={latexCitation}
                            onChange={(e) => setLatexCitation(e.target.value)}
                        />
                    </Pane>

                    <Pane display="flex" justifyContent="flex-end">
                        <Button type="submit" appearance="primary">
                            Submit
                        </Button>
                    </Pane>
                </form>
            </Pane>
        </div>
    );
};

export default PublicationForm;

// import React, { useState } from "react";
// import { TextInputField, Textarea, Select, Button } from "evergreen-ui";

// const PublicationForm = () => {
//     const [title, setTitle] = useState("");
//     const [authors, setAuthors] = useState("");
//     const [publicationType, setPublicationType] = useState("");
//     const [publicationYear, setPublicationYear] = useState("");
//     const [journalName, setJournalName] = useState("");
//     const [abstract, setAbstract] = useState("");
//     const [publicationUrl, setPublicationUrl] = useState("");
//     const [latexCitation, setLatexCitation] = useState("");

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         // Handle form submission logic here, e.g., storing data to a database

//         // Reset form fields after submission
//         setTitle("");
//         setAuthors("");
//         setPublicationType("");
//         setPublicationYear("");
//         setJournalName("");
//         setAbstract("");
//         setPublicationUrl("");
//         setLatexCitation("");
//     };

//     return (
//         <form onSubmit={handleSubmit}>
//             <TextInputField
//                 label="Title"
//                 value={title}
//                 onChange={(e) => setTitle(e.target.value)}
//                 required
//             />

//             <TextInputField
//                 label="Authors"
//                 value={authors}
//                 onChange={(e) => setAuthors(e.target.value)}
//                 required
//             />

//             <Select
//                 label="Publication Type"
//                 value={publicationType}
//                 onChange={(e) => setPublicationType(e.target.value)}
//                 required
//             >
//                 <option value="international conference">
//                     International Conference
//                 </option>
//                 <option value="international journal">
//                     International Journal
//                 </option>
//                 <option value="book chapter">Book Chapter</option>
//                 <option value="thesis">Thesis</option>
//                 <option value="presentation">Presentation</option>
//             </Select>

//             <TextInputField
//                 label="Publication Year"
//                 value={publicationYear}
//                 onChange={(e) => setPublicationYear(e.target.value)}
//                 required
//                 type="number"
//             />

//             <TextInputField
//                 label="Journal/Conference Name"
//                 value={journalName}
//                 onChange={(e) => setJournalName(e.target.value)}
//             />

//             <Textarea
//                 label="Abstract"
//                 value={abstract}
//                 onChange={(e) => setAbstract(e.target.value)}
//             />

//             <TextInputField
//                 label="Publication URL"
//                 value={publicationUrl}
//                 onChange={(e) => setPublicationUrl(e.target.value)}
//                 type="url"
//             />

//             <Textarea
//                 label="Latex Citation"
//                 value={latexCitation}
//                 onChange={(e) => setLatexCitation(e.target.value)}
//             />

//             <Button type="submit">Submit</Button>
//         </form>
//     );
// };

// export default PublicationForm;
