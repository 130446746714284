import {
    Box,
    Grid,
    IconButton,
    Link,
    Stack,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useEffect } from "react";
// import logo from "../../static/favicon.png";
import mypic from "../../static/jpg/00.jpg";
import { useLocation } from "react-router-dom";
import { links } from "./links";
// import { BiBorderRadius } from "react-icons/bi";

interface Props {
    setSelectedIndex: React.Dispatch<React.SetStateAction<number>>;
}

export default function Home({ setSelectedIndex }: Props) {
    const { pathname } = useLocation();
    useEffect(() => {
        setSelectedIndex(-1);
    }, [setSelectedIndex]);

    useEffect(() => {
        document.title = process.env.REACT_APP_NAME!;
    }, [pathname]);

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: `calc(100vh - 20px - 33px)` }}
        >
            <Grid item xs={3}>
                <Stack
                    direction={{ xs: "column", sm: "row-reverse" }}
                    spacing={2}
                >
                    <Tooltip title="I'm not anti-social, I'm just not user-friendly" arrow placement="right">
                        <Box display="flex" sx={{ justifyContent: "center", }}>
                            {/* <img src={logo} width="100px" alt="logo" /> */}
                            <img src={mypic} width="133px" alt="logo" style={{ borderTopLeftRadius: "45%", borderBottomRightRadius: "45%" }}/>
                            {/* <img src={mypic} width="133px" alt="logo" style={{ borderTopRightRadius: "45%", borderBottomLeftRadius: "45%" }}/> */}
                            {/* <img src={mypic} width="133px" alt="logo" style={{ borderRadius:"50%", background: "radial-gradient(ellipse at center, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%,rgba(255,255,255,1) 110%)" }}/> */}
                        </Box>
                    </Tooltip>
                    <Box>
                        <Grid
                            display="flex"
                            justifyContent={{ xs: "center", sm: "flex-start" }}
                        >
                            <Typography variant="h3">
                                {process.env.REACT_APP_NAME}
                            </Typography>
                        </Grid>
                        <Grid
                            display="flex"
                            justifyContent={{ xs: "center", sm: "flex-start" }}
                        >
                            <Typography variant="subtitle1" gutterBottom>
                                The right way, is the hard way. -- Nikos Kazantzakis --
                            </Typography>
                        </Grid>
                        <Grid
                            display="flex"
                            justifyContent={{ xs: "center", sm: "flex-start" }}
                        >
                            <Stack direction="row" spacing={0.4}>
                                {links.map((link) => (
                                    <Tooltip
                                        key={link.index}
                                        title={link.title}
                                        arrow
                                    >
                                        <Link
                                            target="_blank"
                                            href={link.href}
                                            underline="none"
                                            color="inherit"
                                        >
                                            <IconButton color="inherit">
                                                {link.icon}
                                            </IconButton>
                                        </Link>
                                    </Tooltip>
                                ))}
                            </Stack>
                        </Grid>
                    </Box>
                </Stack>
            </Grid>
        </Grid>
    );
}
