import { Pane, Pill, Text, Icon, Strong, Heading } from "evergreen-ui";
import {
    // faGraduationCap,
    faLocationDot,
    // faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { VscLocation } from "react-icons/vsc";
import { FaGraduationCap } from "react-icons/fa";
// import { Small } from "evergreen-ui/types";

const EducationList = () => {
    const educationItems = [
        {
            degree: "Ph.D. in Recommender Systems with Real-life applications",
            university: "Harokopio University of Athens",
            department:
                "School of Digital Technology | Dept. of Informatics and Telematics",
            city: "Tavros",
            country: "Greece",
            graduationYear: "2023",
        },
        {
            degree: 'MSc in Informatics and Telematics - "MSc in Web Engineering"',
            university: "Harokopio University of Athens",
            department:
                "School of Digital Technology | Dept. of Informatics and Telematics",
            city: "Tavros",
            country: "Greece",
            graduationYear: "2014",
        },
        {
            degree: "BSc in Electronics Engineering",
            university: "Piraeus University of Applied Sciences",
            department:
                "School of Engineering | Dept. of Electronics Engineering",
            city: "Piraeus",
            country: "Greece",
            graduationYear: "2007",
        },
    ];

    return (
        <Pane
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop={33}
        >
            <Pane
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                // borderBottom="1px solid #e8e8e8"
                padding={8}
                marginBottom={20}
                position="relative"
            >
                {/* <Text size={500} fontWeight={600}> */}
                <Heading size={700} marginTop={5} color="#3279CB">
                    Education
                </Heading>
                {/* </Text> */}
                <div
                    style={{
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                        width: "15%",
                        height: "33%",
                        borderBottom: "1px solid #3366FF",
                        borderRight: "1px solid #3279CB",
                    }}
                />
            </Pane>
            {educationItems.map((item, index) => (
                <Pane
                    key={index}
                    display="flex"
                    alignItems="center"
                    marginBottom={16}
                    // elevation={2}
                    // padding={8}
                >
                    {/* <Pill
                        width={45}
                        height={124}
                        borderRadius={32}
                        marginRight={16}
                        background="linear-gradient(135deg, #6997d5, #3f69a8)"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        paddingX={12}
                    >
                        <Icon
                            icon={FaGraduationCap}
                            color="ice"
                            size={16}
                            marginTop={8}
                        />
                        <Text color="ice" marginTop={8}>
                            {item.graduationYear}
                        </Text>
                    </Pill> */}
                    <Pane display="flex" flexDirection="column">
                        <Pill
                            width={45}
                            height={62}
                            borderRadius={32}
                            marginRight={16}
                            borderTopLeftRadius={32}
                            borderTopRightRadius={32}
                            borderBottomLeftRadius={0}
                            borderBottomRightRadius={0}
                            background="#2c4877"
                            // background="#3279CB"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            paddingX={10}
                        >
                            {
                                <Pane
                                    borderRadius="50%"
                                    width={30}
                                    height={30}
                                    // background="rgba(0, 0, 0, 0.13)"
                                    // background="#263F68"
                                    background="#3279CB"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Icon
                                        icon={FaGraduationCap}
                                        color="rgba(255, 255, 255, 1.00)"
                                        size={16}
                                    />
                                </Pane>
                            }
                        </Pill>
                        <Pane
                            borderRadius="50%"
                            width={45}
                            height={1}
                            background="#2c4877"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Pane
                                height={1}
                                width="84%"
                                // width="100%"
                                background="White"
                                marginY={0}
                            />
                        </Pane>
                        <Pill
                            width={45}
                            height={62}
                            borderRadius={32}
                            marginRight={16}
                            borderBottomLeftRadius={32}
                            borderBottomRightRadius={32}
                            borderTopLeftRadius={0}
                            borderTopRightRadius={0}
                            // background="#263F68"
                            background="#2c4877"
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            paddingX={10}
                            // backgroundColor="#3279CB"
                        >
                            {
                                <Text
                                    // color="rgba(255, 255, 255, 0.82)"
                                    color="white"
                                    fontSize={14}
                                >
                                    {item.graduationYear}
                                </Text>
                            }
                        </Pill>
                    </Pane>

                    {/* <Pill
                        width={45}
                        height={124}
                        borderRadius={32}
                        marginRight={16}
                        background="#2c4877" //linear-gradient(135deg, #6997d5, #3f69a8)
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        paddingX={10}
                        alignItems="center"
                    >
                        <Pane
                            borderRadius="50%"
                            width={30}
                            height={30}
                            background="rgba(0, 0, 0, 0.13)"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Icon
                                icon={FaGraduationCap}
                                color="rgba(255, 255, 255, 0.82)"
                                size={16}
                            />
                        </Pane>
                        <Pane
                            height={1}
                            width="100%"
                            background="White"
                            marginY={24}
                        />
                        <Text color="rgba(255, 255, 255, 0.82)">
                            {item.graduationYear}
                        </Text>
                    </Pill> */}
                    <Pane
                        width={540}
                        height={124}
                        display="flex"
                        justifyContent="space-between"
                        padding={8}
                        background="white"
                        borderRadius={5}
                        elevation={2}
                    >
                        <Pane
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                        >
                            {/* <Pane> */}
                            {/* <Text fontFamily="Titillium Web" size={400}>
                                {item.degree}
                                {"\n"}
                            </Text> */}
                            <Strong
                                size={400}
                                fontSize={14}
                                marginTop={5}
                                color="gray800"
                            >
                                {item.degree}
                            </Strong>
                            {/* </Pane> */}
                            {/* <Pane> */}
                            <Strong
                                size={400}
                                fontSize={14}
                                marginTop={5}
                                color="navy"
                            >
                                {item.university}
                                {"\n"}
                            </Strong>
                            {/* </Pane> */}
                            {/* <Pane> */}
                            <Text
                                size={400}
                                fontSize={12}
                                marginTop={5}
                                color="gray800"
                            >
                                {item.department}
                            </Text>
                            {/* </Pane> */}
                        </Pane>
                        {/* <Icon
                            icon={VscLocation}
                            color="gray"
                            size={20}
                        />
                        <Text marginLeft={0}>ATHENS</Text> */}
                        <Pane display="flex" alignItems="flex-start">
                            <Icon
                                // icon={VscLocation}
                                icon={<FontAwesomeIcon icon={faLocationDot} />}
                                size={18}
                                // color="#727272"
                                color="#3279cb"
                                paddingRight={2}
                                marginTop={5}
                            />
                            <Text
                                marginLeft={2}
                                marginRight={5}
                                marginTop={5}
                                color="#3279cb"
                                fontSize={11}
                            >
                                ATHENS
                            </Text>
                        </Pane>
                    </Pane>
                </Pane>
            ))}
        </Pane>
    );
};

export default EducationList;
