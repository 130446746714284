import React from "react";
import {
    Pane,
    Heading,
    Text,
    // TextInput,
    Button,
    // Paragraph,
    Icon,
    TextInputField,
    TextareaField,
    toaster,
} from "evergreen-ui";
import {
    // faEnvelope,
    // faEnvelopeCircleCheck,
    faEnvelopeOpen,
    // faEnvelopeOpenText,
    // faEnvelopeSquare,
    // faEnvelopesBulk,
    // faGlobe,
    // faGlobeEurope,
    // faGraduationCap,
    // faLocationDot,
    // faMap,
    // faMapMarker,
    faMapSigns,
    faPhone,
    // faPhoneAlt,
    // faPhoneSlash,
    // faPhoneSquare,
    // faPhoneSquareAlt,
    // faTerminal,
    // faVoicemail,
    // faVolumeControlPhone,
    // faMapMarker,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//     FaBriefcase,
//     // FaGraduationCap,
//     // FaLocationArrow,
//     // FaMapMarker,
//     // FaMapPin,
// } from "react-icons/fa";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import "../../static/Amstrad.css"; // Import a CSS file for styling

<style>
    @keyframes blink{" "}
    {50 %
        {
            opacity: 0,
        }}
</style>;

const fullName = "Shaik";
const email = "sardianos@hua.gr";
const message = "Hello world!";

const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    name: Yup.string().required("Name is required"),
    message: Yup.string().required("Message is required"),
});

const ContactForm = () => {
    const handleFormSubmit = (values, { resetForm }) => {
        // Check for validation errors
        if (validationSchema.isValidSync(values)) {
            // Simulate sending an email
            // Replace this with actual email sending logic
            setTimeout(() => {
                // Sending email logic here...
                // If email is successfully sent, show success toast
                toaster.success("Your message has been sent.");
                resetForm();
            }, 1000);
        } else {
            // Display a danger toaster for validation errors
            toaster.danger("Please fill out the required fields correctly.");
        }
    };

    return (
        <Formik
            initialValues={{ email: "", name: "", message: "" }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            {({ errors, touched }) => (
                <Form>
                    <Field name="email">
                        {({ field }) => (
                            <TextInputField
                                label=""
                                {...field}
                                isInvalid={errors.email && touched.email}
                                validationMessage={errors.email}
                                placeholder="Provide your email"
                                borderStyle="dashed"
                                borderColor="#02b747"
                            />
                        )}
                    </Field>
                    <Field name="name">
                        {({ field }) => (
                            <TextInputField
                                label=""
                                {...field}
                                isInvalid={errors.name && touched.name}
                                validationMessage={errors.name}
                                placeholder="Provide your full name"
                                borderStyle="dashed"
                                borderColor="#02b747"
                            />
                        )}
                    </Field>
                    <Field name="message">
                        {({ field }) => (
                            <TextareaField
                                label=""
                                {...field}
                                isInvalid={errors.message && touched.message}
                                validationMessage={errors.message}
                                placeholder="Enter your mesaage here..."
                                borderStyle="dashed"
                                borderColor="#02b747"
                            />
                        )}
                    </Field>
                    <Button appearance="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

const Contact = () => {
    // const [value, setValue] = React.useState("");
    // console.log("value", value);
    return (
        <Pane
            display="flex"
            flexDirection="column"
            marginX={20}
            marginY={20}
            justifyContent="center"
            alignItems="center"
        >
            <Pane
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginTop={12}
            >
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    // borderBottom="1px solid #e8e8e8"
                    padding={8}
                    marginBottom={20}
                    position="relative"
                >
                    {/* <Text size={500} fontWeight={600}> */}
                    <Heading size={700} marginTop={5} color="#3279CB">
                        Contact me
                    </Heading>
                    {/* </Text> */}
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            width: "15%",
                            height: "33%",
                            borderBottom: "1px solid #3366FF",
                            borderRight: "1px solid #3279CB",
                        }}
                    />
                </Pane>
            </Pane>
            {/* Google Maps Pane */}
            <Pane
                width="100%"
                height={400}
                background="#f0f0f0"
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginBottom={10}
                // marginX={15}
                borderRadius={5}
            >
                {/* You can use an iframe to embed Google Maps */}

                <iframe
                    title="Google Maps"
                    width="99%"
                    height="100%"
                    frameBorder="0"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3145.641695625797!2d23.6983868127761!3d37.96215140122518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1bcf701a75115%3A0xdd7aac5078406226!2sOmirou%209%2C%20Tavros%20177%2078!5e0!3m2!1sen!2sgr!4v1698135170633!5m2!1sen!2sgr"
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </Pane>

            {/* Contact Form and Contact Info */}

            <Pane
                display="flex"
                flexDirection="row"
                width="40%"
                justifyContent="center"
                alignItems="center"
            >
                {/* Contact Form */}

                {/* Contact Info */}
                <Pane
                    flex={1}
                    padding={20}
                    background="#fdfdfd"
                    display="flex"
                    flexDirection="column"
                    marginLeft={2}
                    elevation={1}
                    borderRadius={5}
                    width="fit-content"
                    paddingLeft="25%"
                    paddingRight="25%"
                >
                    {/* <Pane
                        flex={1}
                        background="#fdfdfd"
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                    > */}
                    <Heading
                        size={600}
                        marginBottom={20}
                        color="#3279CB"
                        justifyContent="center"
                        alignItems="center"
                    >
                        Contact Information
                    </Heading>
                    {/* </Pane> */}
                    <Pane display="flex" marginBottom={15}>
                        <Icon
                            // icon={<FontAwesomeIcon icon={faGlobe} />}
                            // icon={<FontAwesomeIcon icon={faGlobeEurope} />}
                            icon={<FontAwesomeIcon icon={faMapSigns} />}
                            size={18}
                            color="#3366FF"
                        />
                        {/* <Text marginLeft={5} marginRight={10}>
                            Address:
                        </Text> */}
                        <Text marginLeft={10}>
                            Harokopio University <br></br>Dept. of Informatics &
                            Telematics <br></br>9 Omirou Street, Tavros
                            <br></br>177 78, Athens, Greece
                        </Text>
                    </Pane>
                    <Pane display="flex" marginBottom={15}>
                        <Icon
                            // icon={
                            //     <FontAwesomeIcon icon={faVolumeControlPhone} />
                            // }
                            icon={<FontAwesomeIcon icon={faPhone} />}
                            // icon={<FontAwesomeIcon icon={faPhoneSquare} />}
                            size={18}
                            color="#3366FF"
                        />
                        {/* <Text marginLeft={5} marginRight={10}>
                            Phone:
                        </Text> */}
                        <Text marginLeft={10}>0030 210 9549 429</Text>
                    </Pane>
                    <Pane display="flex" marginBottom={15}>
                        <Icon
                            // icon={<FontAwesomeIcon icon={faEnvelope} />}
                            icon={<FontAwesomeIcon icon={faEnvelopeOpen} />}
                            // icon={<FontAwesomeIcon icon={faTerminal} />}
                            size={18}
                            color="#3366FF"
                        />
                        {/* <Text marginLeft={5} marginRight={10}>
                            Email:
                        </Text> */}
                        <Text marginLeft={10}>sardianos[at]hua[dot]gr</Text>
                    </Pane>
                    {/* <Pane
                        borderRadius="50%"
                        borderBottomRightRadius="0%"
                        width={25}
                        height={25}
                        // background="rgba(0, 0, 0, 0.13)"
                        // background="#263F68"
                        background="#3366FF"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Icon
                            icon={<FontAwesomeIcon icon={faEnvelopeOpen} />}
                            color="rgba(255, 255, 255, 1.00)"
                            // size={2}
                        />
                    </Pane> */}
                </Pane>
            </Pane>
        </Pane>
    );
};
export default Contact;
