export const pages = [
    { index: 0, name: "$whoami", route: "/whoami", visible: true },
    { index: 1, name: "Publications", route: "/publications", visible: true },
    { index: 2, name: "Projects", route: "/projects", visible: true },
    {
        index: 3,
        name: "Education",
        route: "/education",
        visible: true,
    },
    { index: 4, name: "Research", route: "/research", visible: false },
    { index: 5, name: "Experience", route: "/experience", visible: true },
    // {
    //     index: 6,
    //     name: "Certifications",
    //     route: "/certifications",
    //     visible: true,
    // },
    {
        index: 7,
        name: "Contact",
        route: "/contact",
        visible: true,
    },
    // {
    //     index: 8,
    //     name: "Bio",
    //     route: "/bio",
    //     visible: true,
    // },
    // {
    //     index: 9,
    //     name: "VerticalTabs",
    //     route: "/vertical-tabs",
    //     visible: true,
    // },
    
    // { index: 10, name: "docs.md", route: "/docs", visible: false },

    // { index: 10, name: "Education", route: "/education2", visible: true },
    // { index: 11, name: "About me", route: "/aboutme", visible: true },
    // { index: 12, name: "Resume", route: "/resume", visible: true },
    // { index: 9, name: "skills.md", route: "/skills", visible: true },
    // {
    //     index: 9,
    //     name: "vertical-tabs",
    //     route: "/vertical-tabs",
    //     visible: true,
    // },
    // {
    //     index: 10,
    //     name: "EducationList",
    //     route: "/educationlist",
    //     visible: true,
    // },
];
