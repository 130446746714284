// import { Google } from "@mui/icons-material";
import {
    // FaMedium,
    // FaBlog,
    FaGithub,
    FaLinkedin,
    // FaEnvelope,
    FaGoogle,
    FaResearchgate,
    FaOrcid,
} from "react-icons/fa";
// import { SiGooglescholar } from "react-icons/si";
// import { IoLogoGoogle } from "react-icons/io";
// import {
//     faOrcid
// } from "@fortawesome/free-brands-svg-icons";

export const links = [
    {
        index: 0,
        title: "Find me on LinkedIn",
        href: "https://www.linkedin.com/in/christos-sardianos/",
        icon: <FaLinkedin />,
    },
    {
        index: 1,
        title: "Find me on Google Scholar",
        href: "https://scholar.google.com/citations?user=bGdLOSgAAAAJ",
        // icon: <IoLogoGoogle />,
        // icon: <Google />,
        // icon: <FaEnvelope />,
        // icon: <SiGooglescholar />,
        icon: <FaGoogle />,
    },
    {
        index: 2,
        title: "Find me on ResearchGate",
        href: "https://www.researchgate.net/profile/Christos-Sardianos",
        icon: <FaResearchgate />,
        // icon: <FaMedium />,
    },
    {
        index: 3,
        title: "Find me on Github",
        href: "https://github.com/SrDNS",
        icon: <FaGithub />,
    },
    {
        index: 4,
        title: "Find me on OrcID",
        href: "https://orcid.org/0000-0001-7262-7310",
        icon: <FaOrcid/>,
    },
];
