import React, { useState, useEffect } from "react";
// import publicationsData from "src/static/myPublications.json";
import publicationsData from "./myPublications.json";
import Box from "@mui/material/Box";
import {
    Pane,
    // Pill,
    Text,
    // Icon,
    Strong,
    Heading,
    Badge,
    IconButton,
    // CogIcon,
    // majorScale,
    // TrashIcon,
    // TickIcon,
    CodeIcon,
    // MoreIcon,
    // ExpandAllIcon,
    Tooltip,
    // Code,
    Combobox,
    Textarea,
} from "evergreen-ui";
// import { useRef } from "react";
import { CopyBlock, dracula } from "react-code-blocks";
// import { red } from "@mui/material/colors";
import {
    Grid,
    // Link,
    Paper,
    // Stack,
    // Typography
} from "@mui/material";
// import {
//     VscRemote,
//     VscError,
//     VscWarning,
//     VscBell,
//     VscFeedback,
//     VscCheck,
// } from "react-icons/vsc";
// import { IoIosGitBranch } from "react-icons/io";
// import hljs from "highlight.js/lib/core";
// import javascript from "highlight.js/lib/languages/javascript";
// import SyntaxHighlighter from "react-syntax-highlighter";
// import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

const PublicationList = () => {
    // const publications = [
    //     {
    //         key: 1,
    //         index: 0,
    //         title: "A scalable solution for personalized recommendations in large-scale social networks",
    //         authors: "Christos Sardianos, Iraklis Varlamis",
    //         publication_location: "18th PCI 14, pp. 1--6, Athens, Greece, ACM",
    //         year: "2014",
    //         month: "10",
    //         day: "02",
    //         abstract:
    //             "The modern trend in many Web 2.0 applications is that users can interact with the applications in terms of social activity, for example they can express their trust for another user or another user’s review etc. Bearing that in mind, creating recommendations for users could go one step further by reclaiming the social network information they share into these applications. This information can be exploited for creating better recommendations but also for optimizing the execution of existing algorithms in large scale datasets. In this paper, we introduce a scalable model for generating personalized user recommendations, which first uses the social information and divides the social graph into subgraphs and then applies Collaborative Filtering on the preferences information related to each subgraph. The results of our experiments show that our proposed model can perform faster than the traditional techniques while producing high level personalized recommendations. Moreover, it allows the parallel execution of the recommendation algorithm in each separate subgraph.",
    //         type: "International Conference",
    //         bibtex: "@inproceedings{sardianos2014scalable, title={A scalable solution for personalized recommendations in large-scale social networks}, author={Sardianos, Christos and Varlamis, Iraklis}, booktitle={Proceedings of the 18th Panhellenic Conference on Informatics}, pages={1--6}, year={2014} }",
    //         url: "https://dl.acm.org/citation.cfm?id=2645801",
    //     },
    //     {
    //         key: 2,
    //         index: 1,
    //         title: "Argument extraction from news",
    //         authors:
    //             "Christos Sardianos, Ioannis Manousos Katakis, Georgios Petasis, Vangelis Karkaletsis",
    //         publication_location: "NAACL HLT 2015, Denver, Colorado, USA",
    //         year: "2015",
    //         month: "06",
    //         day: "05",
    //         abstract:
    //             "Argument extraction is the task of identifying arguments, along with their components in text. Arguments can be usually decomposed into a claim and one or more premises justifying it. The proposed approach tries to identify segments that represent argument elements (claims and premises) on social Web texts (mainly news and blogs) in the Greek language, for a small set of thematic domains, including articles on politics, economics, culture, various social issues, and sports. The proposed approach exploits distributed representations of words, extracted from a large non-annotated corpus. Among the novel aspects of this work is the thematic domain itself which relates to social Web, in contrast to traditional research in the area, which concentrates mainly on law documents and scientific publications. The huge increase of social web communities, along with their user tendency to debate, makes the identification of arguments in these texts a necessity. In addition, a new manually annotated corpus has been constructed that can be used freely for research purposes. Evaluation results are quite promising, suggesting that distributed representations can contribute positively to the task of argument extraction.",
    //         type: "International Conference",
    //         bibtex: "@inproceedings{sardianos2015argument, title={Argument extraction from news}, author={Sardianos, Christos and Katakis, Ioannis Manousos and Petasis, Georgios and Karkaletsis, Vangelis}, booktitle={Proceedings of the 2nd Workshop on Argumentation Mining}, journal={NAACL HLT 2015}, pages={56--66}, year={2015} }",
    //         url: "https://www.aclweb.org/anthology/W15-0508",
    //     },
    //     {
    //         key: 3,
    //         index: 2,
    //         title: "PaloPro: a platform for knowledge extraction from big social data and the news",
    //         authors:
    //             "Nantia Makrynioti, Andreas Grivas, Christos Sardianos, Nikos Tsirakis, Iraklis Varlamis, Vasilis Vassalos, Vassilis Poulopoulos, Panagiotis Tsantilas",
    //         publication_location:
    //             "International Journal of Big Data Intelligence, 4(1), 3-22.",
    //         year: "2017",
    //         month: "01",
    //         day: "02",
    //         abstract:
    //             "Palo Pro is a platform that aggregates textual content from social media and news sites in different languages, analyses them using a series of text mining algorithms and provides advanced analytics to journalists and social media marketeers. The platform capitalizes on the abundance of social media sources and the information they provide for persons, products and events. The main problem that the platform faces is the scalability of existing solutions regarding the huge amounts of data that are collected continuously and are expected to grow further with the expansion of the platform to more countries. In order to handle the increasing load and be able to handle multi-lingual content, we have adopted language-independent techniques at all levels and from an engineering point of view, we have designed a system that takes advantage of parallel distributed computing technologies and cloud infrastructure. Different systems handle data aggregation, data processing and knowledge extraction and others deal with the integration and visualization of knowledge. In this paper, we focus on a specific 1 set of text mining tasks, which plays an important role for the platform’s analytics. Namely, Named Entity Recognition from texts and Sentiment Analysis to extract the sentiment associated with the corresponding identified entities.",
    //         type: "International Journal",
    //         bibtex: "@article{makrynioti2017palopro, title={PaloPro: a platform for knowledge extraction from big social data and the news}, author={Makrynioti, Nantia and Grivas, Andreas and Sardianos, Christos and Tsirakis, Nikos and Varlamis, Iraklis and Vassalos, Vasilis and Poulopoulos, Vassilis and Tsantilas, Panagiotis}, journal={International Journal of Big Data Intelligence}, volume={4}, number={1}, pages={3--22}, year={2017}, publisher={Inderscience Publishers (IEL)} }",
    //         url: "https://www.inderscienceonline.com/doi/abs/10.1504/IJBDI.2017.081185",
    //     },
    //     {
    //         key: 4,
    //         index: 3,
    //         title: "Scaling Collaborative Filtering to large–scale Bipartite Rating Graphs using Lenskit and Spark",
    //         authors: "Christos Sardianos, Iraklis Varlamis, Magdalini Eirinaki",
    //         publication_location:
    //             "2017 IEEE BigDataService, San Francisco, USA",
    //         year: "2017",
    //         month: "04",
    //         day: "07",
    //         abstract:
    //             "Popular social networking applications such as Facebook, Twitter, Friendster, etc. generate very large graphs with different characteristics. These social networks are huge, comprising millions of nodes and edges that push existing graph mining algorithms and architectures to their limits. In product-rating graphs, users connect with each other and rate items in tandem. In such bipartite graphs users and items are the nodes and ratings are the edges and collaborative filtering algorithms use the edge information (i.e. user ratings for items) in order to suggest items of potential interest to users. Existing algorithms can hardly scale up to the size of the entire graph and require unlimited resources to finish. This work employs a machine learning method for predicting the performance of Collaborative Filtering algorithms using the structural features of the bipartite graphs. Using a fast graph partitioning algorithm and information from the user-friendship graph, the original bipartite graph is partitioned into different schemes (i.e. sets of smaller bipartite graphs). The schemes are evaluated against the predicted performance of the Collaborative Filtering algorithm and the best partitioning scheme is employed for generating the recommendations. As a result, the Collaborative Filtering algorithms are applied to smaller bipartite graphs, using limited resources and allowing the problem to scale or be parallelized. Tests on a large, real-life, rating graph, show that the proposed method allows the collaborative filtering algorithms to run in parallel and complete using limited resources.",
    //         type: "International Conference",
    //         bibtex: "@inproceedings{sardianos2017scaling, title={Scaling collaborative filtering to large--scale bipartite rating graphs using lenskit and spark}, author={Sardianos, Christos and Varlamis, Iraklis and Eirinaki, Magdalini}, booktitle={2017 IEEE Third International Conference on Big Data Computing Service and Applications (BigDataService)}, pages={70--79}, year={2017}, organization={IEEE} }",
    //         url: "https://ieeexplore.ieee.org/document/7944922/",
    //     },
    //     {
    //         key: 5,
    //         index: 4,
    //         title: "A Survey on the Scalability of Recommender Systems for Social Networks",
    //         authors: "Christos Sardianos, Nikolaos Tsirakis, Iraklis Varlamis",
    //         publication_location:
    //             "Springer International Publishing, Social Networks Science: Design, Implementation, Security, and Challenges: From Social Networks Analysis to Social Networks Intelligence",
    //         year: "2018",
    //         month: "06",
    //         day: "19",
    //         abstract:
    //             "It is typical among online social networks’ users to share their status, activity and other information with fellow users, to interact on the information shared by others and to express their trust or interest for each other. The result is a rich information repository which can be used to improve the user experience and increase their engagement if handled properly. In order to create a personalized user experience in social networks, we need data management solutions that scale well on the huge amounts of information generated on a daily basis. The social information of an online social network can be useful both for improving content personalization but also for allowing existing algorithms to scale to huge datasets. All current real-world large-scale recommender systems have invested on scalable distributed database systems for data storage and parallel and distributed algorithms for finding recommendations. This chapter, focuses on collaborative filtering algorithms for recommender systems, briefly explains how they work and what their limitations are.",
    //         type: "Book Chapter",
    //         bibtex: "@incollection{sardianos2018survey, title={A survey on the scalability of recommender systems for social networks}, author={Sardianos, C and Tsirakis, N and Varlamis, I}, booktitle={Social Networks Science: Design, Implementation, Security, and Challenges}, pages={89--110}, year={2018}, publisher={Springer} }",
    //         url: "https://link.springer.com/chapter/10.1007/978-3-319-90059-9_5",
    //     },
    // ];

    const [publications, setPublications] = useState(publicationsData);

    const [expandedPublication, setExpandedPublication] = useState(null);

    const handlePublicationClick = (index) => {
        setExpandedPublication(index === expandedPublication ? null : index);
    };

    const renderBibtex = (bibtex) => {
        if (!bibtex) {
            return (
                <CopyBlock
                    text={"No bibtex available for this publication."}
                    showLineNumbers
                    language="latex"
                    theme={dracula}
                    wrapLines
                />
            );
        }
        return (
            <CopyBlock
                text={bibtex
                    .split("},")
                    .join("},\n ")
                    .split(", title")
                    .join(", \n  title")
                    .split(", author")
                    .join(", \n  author")
                    .split(" }")
                    .join("\n}")}
                showLineNumbers
                language="json"
                theme={dracula}
                wrapLines
            />
        );
    };

    const renderBadge = (type) => {
        let color;

        switch (type) {
            case "International Journal":
                color = "green";
                break;
            case "International Conference":
                color = "blue";
                break;
            case "Book Chapter":
                color = "red";
                break;
            case "Thesis":
                // color = "teal";
                color = "yellow";
                break;
            default:
                color = "gray";
                break;
        }

        return (
            <Badge color={color} marginRight={8}>
                {type}
            </Badge>
        );
    };

    const getMonthName = (monthNumber) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString("en-US", { month: "short" });
    };

    // const currentYear = new Date().getFullYear();

    const [selectedType, setSelectedType] = useState("All publications");
    const [typeCounts, setTypeCounts] = useState({});
    // console.log(typeCounts);
    const [comboBoxItems, setComboBoxItems] = useState([]);
    const [placeholder, setPlaceholder] = useState("");
    // console.log(placeholder);

    const handleSelectionChange = (selected) => {
        // Execute the first action
        // console.log(selected);
        const myArray = selected.split(" (");
        let type = myArray[0];
        // console.log(type);
        setSelectedType(type);
        // Execute the second action (you can add more actions here)
        // Example: performAnotherAction();
    };

    // Function to count publications by type
    const countPublicationsByType = () => {
        const typeCounts = {};

        publications.forEach((publication) => {
            const type = publication.type;
            if (typeCounts[type]) {
                typeCounts[type]++;
            } else {
                typeCounts[type] = 1;
            }
        });
        // Calculate the total count
        const totalCount = Object.values(typeCounts).reduce(
            (acc, count) => acc + count,
            0
        );

        // Generate items for each type with count
        const typeItems = Object.keys(typeCounts).map(
            (type) => `${type} (${typeCounts[type]})`
        );

        // Create ComboBox items with "All publications" and total count
        const comboBoxItems = [
            `All publications (${totalCount})`,
            ...typeItems,
        ];

        // Update the placeholder to match the first item
        setPlaceholder(comboBoxItems[0]);

        // Set the typeCounts state to re-render the component
        setTypeCounts(typeCounts);

        // Set the ComboBox items
        setComboBoxItems(comboBoxItems);
    };

    // const [typeCounts, setTypeCounts] = useState({});

    // Call the function when the component loads
    useEffect(() => {
        countPublicationsByType();
    }); // The empty dependency array ensures it runs only once on component load

    // Calculate the total count
    // const totalCount = Object.values(typeCounts).reduce(
    //     (acc, count) => acc + count,
    //     0
    // );

    // // Create ComboBox items with counts
    // const comboBoxItems = [
    //     // "All publications (Total Count)"
    //     `All publications (${totalCount})`,

    //     // Generate items for each type with count
    //     ...Object.keys(typeCounts).map(
    //         (type) => `${type} (${typeCounts[type]})`
    //     ),
    // ];

    return (
        <Pane width="80%" alignItems="center" paddingX={16} margin="auto">
            <Pane
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginTop={33}
            >
                <Pane
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    // borderBottom="1px solid #e8e8e8"
                    padding={8}
                    marginBottom={20}
                    position="relative"
                >
                    {/* <Text size={500} fontWeight={600}> */}
                    <Heading size={700} marginTop={5} color="#3279CB">
                        Publications
                    </Heading>
                    {/* </Text> */}
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            width: "15%",
                            height: "33%",
                            borderBottom: "1px solid #3366FF",
                            borderRight: "1px solid #3279CB",
                        }}
                    />
                </Pane>
            </Pane>
            <Pane
                display="flex"
                flexDirection="row"
                // justifyContent="center" // Horizontally center the content
                // paddingBottom={16}
                // marginBottom={4}
                // borderBottom="1px solid red"
            >
                <Pane width="50%" marginLeft={16}></Pane>
                {/* <Pane
                    width="50%"
                    marginLeft={16}
                    marginTop={16}
                    marginBottom={16}
                    padding={16}
                    paddingY={8}
                    borderRight="0.5px solid #c1c4d6"
                    // leftborderRadius={4}
                    // border-top-left-radius={4}
                    // border-bottom-left-radius={4}
                    borderTopLeftRadius={4}
                    borderBottomLeftRadius={4}
                    backgroundColor="#FFFFFF"
                    elevation={1}
                >
                    <Combobox
                        openOnFocus
                        items={comboBoxItems}
                        // items={[
                        //     "All publications",
                        //     "International Journal",
                        //     "International Conference",
                        //     "Book Chapter",
                        //     "Thesis",
                        // ]}
                        initialSelectedItem={comboBoxItems[0]} // Set the first item with the total count
                        width="100%"
                        height={33}
                        onChange={(selected) => handleSelectionChange(selected)}
                        // onChange={(selected) => console.log(selected)}
                        placeholder={comboBoxItems[0]} // Set the first item with the total count
                        autocompleteProps={{
                            // Used for the title in the autocomplete.
                            title: "Sorting by Date",
                        }}
                    />
                </Pane> */}
                <Pane
                    width="50%"
                    marginTop={16}
                    marginBottom={16}
                    paddingX={16}
                    paddingY={8}
                    // borderLeft="0.5px solid #c1c4d6"
                    // borderRadius={4}
                    borderTopRightRadius={4}
                    borderBottomRightRadius={4}
                    backgroundColor="#FFFFFF"
                    elevation={1}
                >
                    <Combobox
                        openOnFocus
                        items={comboBoxItems}
                        // items={[
                        //     "All publications",
                        //     "International Journal",
                        //     "International Conference",
                        //     "Book Chapter",
                        //     "Thesis",
                        // ]}
                        initialSelectedItem={comboBoxItems[0]} // Set the first item with the total count
                        width="100%"
                        height={33}
                        onChange={(selected) => handleSelectionChange(selected)}
                        placeholder={comboBoxItems[0]} // Set the first item with the total count
                        autocompleteProps={{
                            // Used for the title in the autocomplete.
                            title: "Publication type",
                        }}
                    />
                </Pane>
            </Pane>
            {publications
                .filter((publication) => {
                    if (selectedType === "All publications") {
                        return true; // Show all publications when "All publications" is selected
                    }
                    return publication.type === selectedType;
                })
                // .sort((a, b) => b.index - a.index) // Sort by index in descending order
                .sort((a, b) => {
                    // Compare by year in descending order
                    const yearComparison = parseInt(b.year) - parseInt(a.year);

                    // If the years are different, return the result of the year comparison
                    if (yearComparison !== 0) {
                        return yearComparison;
                    }

                    // If the years are the same, compare by month in descending order
                    return parseInt(b.month) - parseInt(a.month);
                })
                .map((publication, index) => (
                    <Pane
                        display="flex"
                        flexDirection="row"
                        paddingBottom={16}
                        marginBottom={16}
                        key={index}
                        marginLeft={16}
                        justifyContent="center"
                        padding={8}
                        background="#F2F2F4"
                        overflow="hidden"
                        borderRadius={5}
                        elevation={1}
                        flexGrow={1}
                    >
                        <Pane paddingX={16}>
                            <Pane
                                width="60px"
                                id="date"
                                backgroundColor="#3366FF"
                                borderRadius={4}
                                overflow="hidden"
                                margin="auto"
                            >
                                <Pane
                                    width="100%"
                                    paddingX={16}
                                    paddingY={8}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                >
                                    <Text fontSize={18} color="white">
                                        {publication.day}
                                    </Text>
                                    <Text fontSize={14} color="white">
                                        {getMonthName(publication.month)}
                                        {/* May */}
                                    </Text>
                                </Pane>
                                <Pane
                                    width="100%"
                                    textAlign="center"
                                    backgroundColor="#2C4877"
                                    paddingY={4}
                                >
                                    <Text fontSize={18} color="white">
                                        {publication.year}
                                    </Text>
                                </Pane>
                            </Pane>
                        </Pane>
                        <Pane
                            // key={index}
                            display="flex"
                            // marginBottom={2}
                            // marginLeft={16}
                            width="84%"
                            flexDirection="column"
                            justifyContent="center"
                            // padding={8}
                            // background="#F2F2F4"
                            // overflow="hidden"
                            // borderRadius={5}
                            // elevation={2}
                            // flexGrow={1}
                            flexGrow={1}
                            paddingX={16}
                            borderLeft="1px solid #c1c4d6"
                        >
                            <Strong
                                size={500}
                                fontSize={14}
                                marginTop={5}
                                marginBottom={5}
                                // color="navy"
                                color="#727272"
                            >
                                {publication.title}
                            </Strong>
                            <Pane marginBottom={8}>
                                <Text size={400} color="gray800">
                                    {/* Authors: {" "} */}
                                    {publication.authors}
                                </Text>
                                <br />
                                <Text size={300} color="#4253b3">
                                    {/* Publisher:{" "} */}
                                    {publication.publication_location}
                                </Text>
                            </Pane>
                            <Pane
                                height={1}
                                width="99%"
                                // borderBottom="1px dashed #a4a4a4"
                                borderBottom="1px dashed #c1c4d6"
                                marginY={0}
                            />
                            {expandedPublication === index && (
                                <Pane marginTop={16}>
                                    <Text>{publication.abstract}</Text>
                                    <br></br>
                                    <Pane
                                        display="flex"
                                        alignItems="left"
                                        justifyContent="space-between"
                                        // borderBottom="1px solid #e8e8e8"
                                        padding={8}
                                        // marginBottom={20}
                                        position="relative"
                                    >
                                        {/* <Text size={500} fontWeight={600}> */}
                                        <Heading
                                            size={500}
                                            marginTop={5}
                                            color="#3279CB"
                                        >
                                            Bibtex
                                        </Heading>
                                        {/* </Text> */}
                                        <div
                                            style={{
                                                position: "absolute",
                                                bottom: 0,
                                                left: 33,
                                                width: "4%",
                                                height: "33%",
                                                borderBottom:
                                                    "1px solid #3366FF",
                                                borderRight:
                                                    "1px solid #3279CB",
                                            }}
                                        />
                                    </Pane>
                                    <Pane
                                        textAlign="left"
                                        background="#ffffff"
                                        // paddingX={33}
                                        // paddingY={16}
                                        marginTop={8}
                                        marginBottom={8}
                                        overflow="hidden"
                                        borderRadius={5}
                                        elevation={1}
                                        flexGrow={1}
                                        width="99%"
                                    >
                                        {/* @article&#123;himeur2021survey,
                                    <br />
                                    title=&#123;A survey of recommender systems
                                    for energy efficiency in buildings:
                                    Principles, challenges and prospects&#125;,{" "}
                                    <br />
                                    author=&#123;Himeur, Yassine and Alsalemi,
                                    Abdullah and Al-Kababji, Ayman and Bensaali,
                                    Faycal and Amira, Abbes and Sardianos,
                                    Christos and Dimitrakopoulos, George and
                                    Varlamis, Iraklis&#125;,
                                    <br />
                                    journal=&#123;Information Fusion&#125;,
                                    <br />
                                    volume=&#123;72&#125;,
                                    <br />
                                    pages=&#123;1--21&#125;,
                                    <br />
                                    year=&#123;2021&#125;,
                                    <br />
                                    publisher=&#123;Elsevier&#125; <br />
                                    &#125; */}
                                        <div
                                            style={{
                                                fontFamily: "MesloLGS NF",
                                            }}
                                            // style={{ fontFamily: "IBM Plex Mono" }}
                                        >
                                            {renderBibtex(publication.bibtex)}
                                        </div>
                                    </Pane>
                                </Pane>
                            )}
                            <Box
                                component={Paper}
                                square
                                elevation={0}
                                // sx={{ height: '3vh' }}
                                sx={{ height: "100%", color: "white" }}
                                display="flex"
                                marginTop={0.5}
                                // marginBottom={0.5}
                            >
                                <Pane
                                    display="flex"
                                    width="100%"
                                    backgroundColor="#F2F2F4"
                                    alignItems="center"
                                    justifyContent="center"
                                    position="relative"
                                    cursor="pointer"
                                    onClick={() =>
                                        handlePublicationClick(index)
                                    }
                                >
                                    {/* Render the Tooltip in the center */}
                                    <Tooltip
                                        content="Read more"
                                        // position="center"
                                        paddingY={0}
                                    >
                                        <IconButton
                                            icon={CodeIcon}
                                            size="small"
                                            color="tint2"
                                            borderStyle="dashed"
                                            borderColor="tint2"
                                        />
                                    </Tooltip>
                                    {/* Create a separate Pane for the right element */}
                                    <Pane
                                        position="absolute"
                                        // top={0}
                                        right={0}
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        sx={{
                                            backgroundColor: "#F2F2F4",
                                            // display: "flex",
                                            // alignItems: "center",
                                            // justifyContent: "flex-end",
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            handlePublicationClick(index)
                                        }
                                    >
                                        {renderBadge(publication.type)}
                                    </Pane>
                                </Pane>
                            </Box>
                            {/* <Box
                                component={Paper}
                                square
                                elevation={0}
                                // sx={{ height: '3vh' }}
                                sx={{ height: "23px", color: "white" }}
                                display="flex"
                                marginTop={0.5}
                                // marginBottom={0.5}
                            >
                                <Grid container>
                                    <Grid
                                        item
                                        width="80%"
                                        sx={{
                                            backgroundColor: "#F2F2F4",
                                            flexGrow: 1, // Fill available space
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            handlePublicationClick(index)
                                        }
                                    >
                                        <Tooltip content="Read more">
                                            <IconButton
                                                icon={CodeIcon}
                                                size="small"
                                                color="tint2"
                                                borderStyle="dashed"
                                                borderColor="tint2"
                                            />
                                        </Tooltip>
                                    </Grid>
                                    <Grid
                                        item
                                        width="20%"
                                        // sx={{ backgroundColor: "#3279CB" }}
                                        sx={{
                                            backgroundColor: "#F2F2F4",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-end",
                                            cursor: "pointer",
                                        }}
                                        onClick={() =>
                                            handlePublicationClick(index)
                                        }
                                    >
                                        {renderBadge(publication.type)}
                                    </Grid>
                                </Grid>
                            </Box> */}
                            {/* <Pane>
                                <Pane
                                    display="flex"
                                    // justifyContent="center"
                                    cursor="pointer"
                                    alignItems="center"
                                    paddingTop={4}
                                    onClick={() =>
                                        handlePublicationClick(index)
                                    }
                                >
                                    <Pane
                                        width="80%"
                                        justifyContent="center"
                                        alignContent="center"
                                        textAlign="center"
                                    >
                                        <Tooltip content="Read more">
                                            <IconButton
                                                icon={CodeIcon}
                                                size="small"
                                                color="tint2"
                                                borderStyle="dashed"
                                                borderColor="tint2"
                                            />
                                        </Tooltip>
                                    </Pane>
                                    <Pane width="20%" textAlign="right">
                                        {renderBadge(publication.type)}
                                    </Pane>
                                </Pane>
                            </Pane> */}
                        </Pane>
                    </Pane>
                ))}
        </Pane>
    );

    // return (
    //     <Pane padding={16}>
    //         {publications.map((publication, index) => (
    //             <Pane
    //                 display="flex"
    //                 flexDirection="row"
    //                 // justifyContent="center" // Horizontally center the content
    //                 paddingBottom={16}
    //                 marginBottom={16}
    //                 // borderBottom="1px solid red"
    //             >
    //                 <Pane>
    //                     <Pane
    //                         id="date"
    //                         backgroundColor="#3366FF"
    //                         borderRadius={4}
    //                         overflow="hidden"
    //                         margin="auto"
    //                     >
    //                         <Pane
    //                             paddingX={16}
    //                             paddingY={8}
    //                             display="flex"
    //                             flexDirection="column"
    //                             alignItems="center"
    //                         >
    //                             <Text fontSize={18} color="white">
    //                                 26
    //                             </Text>
    //                             <Text color="white">Jan</Text>
    //                         </Pane>
    //                         <Pane
    //                             width="100%"
    //                             textAlign="center"
    //                             backgroundColor="#2C4877"
    //                             paddingY={4}
    //                         >
    //                             <Text fontSize={18} color="white">
    //                                 2021
    //                             </Text>
    //                         </Pane>
    //                     </Pane>
    //                 </Pane>
    //                 <Pane
    //                     // flexDirection="row"
    //                     // paddingBottom={16}
    //                     // marginBottom={16}
    //                     key={index}
    //                     display="flex"
    //                     // alignItems="center"
    //                     marginBottom={2}
    //                     // marginX={4}
    //                     marginLeft={16}
    //                     width="84%"
    //                     flexDirection="column"
    //                     justifyContent="center"
    //                     padding={8}
    //                     background="#F2F2F4"
    //                     // borderBottom="1px solid red"
    //                     overflow="hidden"
    //                     borderRadius={5}
    //                     // boxShadow="0px 1px 4px rgba(67, 90, 111, 0.3)"
    //                     elevation={2}
    //                     flexGrow={1}
    //                     // paddingX={16}
    //                 >
    //                     {/* <Heading is="h5" color="navy">
    //                         Heading h3
    //                     </Heading> */}
    //                     <Strong
    //                         size={500}
    //                         fontSize={14}
    //                         marginTop={5}
    //                         marginBottom={5}
    //                         color="navy"
    //                     >
    //                         {publication.title}
    //                     </Strong>
    //                     <Pane marginBottom={8}>
    //                         <Text size={400} color="gray800">
    //                             Authors: {publication.authors}
    //                         </Text>
    //                     </Pane>
    //                     <Pane>
    //                         <Text size={400} color="gray800">
    //                             Publisher: {publication.publisher}
    //                         </Text>
    //                     </Pane>
    //                     <Pane
    //                         height={1}
    //                         width="99%"
    //                         borderBottom="1px dashed #a4a4a4"
    //                         marginY={0}
    //                     />
    //                     {expandedPublication === index && (
    //                         <Pane marginTop={16}>
    //                             <Text>{publication.abstract}</Text>
    //                         </Pane>
    //                     )}
    //                     {/* <Pane display="flex" justifyContent="flex-end" margin={5}> */}
    //                     {/* <Pane display="flex" alignItems="center">
    //                         <IconButton
    //                             icon={CodeIcon}
    //                             size="small"
    //                             marginRight={majorScale(1)}
    //                         />
    //                         <IconButton
    //                             icon={MoreIcon}
    //                             intent="success"
    //                             size="small"
    //                             marginRight={majorScale(1)}
    //                         />
    //                         <IconButton icon={ExpandAllIcon} size="small" />
    //                     </Pane> */}
    //                     <Pane
    //                     // display="flex"
    //                     // flexDirection="row"
    //                     // alignItems="center"
    //                     >
    //                         <Pane
    //                             display="flex"
    //                             // justifyContent="center"
    //                             cursor="pointer"
    //                             alignItems="center"
    //                             paddingTop={4}
    //                             onClick={() => handlePublicationClick(index)}
    //                         >
    //                             {/* <Pane id="expand_abstract" display="flex">
    //                             <IconButton
    //                                 icon={CodeIcon}
    //                                 size="small"
    //                                 marginRight={majorScale(1)}
    //                             />
    //                         </Pane> */}
    //                             <Pane
    //                                 width="80%"
    //                                 justifyContent="center"
    //                                 alignContent="center"
    //                                 textAlign="center"
    //                             >
    //                                 <Tooltip content="Read more">
    //                                     <IconButton
    //                                         icon={CodeIcon}
    //                                         size="small"
    //                                         backgroundColor="tint2"
    //                                         color="tint2"
    //                                         borderStyle="dashed"
    //                                         borderColor="tint2"
    //                                     />
    //                                 </Tooltip>
    //                             </Pane>
    //                             <Pane width="20%" textAlign="right">
    //                                 {renderBadge(publication.type)}
    //                             </Pane>
    //                         </Pane>
    //                     </Pane>
    //                 </Pane>
    //             </Pane>
    //             // <Pane display="flex">footer</Pane>
    //             // <Pane
    //             //     display="flex"
    //             //     flexDirection="column"
    //             //     alignItems="center"
    //             //     marginTop={33}
    //             // >
    //             //     vfvvfg
    //             // </Pane>
    //         ))}
    //     </Pane>
    // );

    // return (
    //     <Pane padding={16}>
    //         <Pane
    //             display="flex"
    //             flexDirection="row"
    //             paddingBottom={16}
    //             marginBottom={16}
    //             borderBottom="1px solid red"
    //         >
    //             <Pane>
    //                 <Pane
    //                     backgroundColor="#3366FF"
    //                     borderRadius={4}
    //                     overflow="hidden"
    //                 >
    //                     <Pane
    //                         paddingX={16}
    //                         paddingY={8}
    //                         display="flex"
    //                         flexDirection="column"
    //                         alignItems="center"
    //                     >
    //                         <Text fontSize={18} color="white">
    //                             26
    //                         </Text>
    //                         <Text color="white">Jan</Text>
    //                     </Pane>
    //                     <Pane
    //                         width="100%"
    //                         textAlign="center"
    //                         backgroundColor="#2C4877"
    //                         paddingY={4}
    //                     >
    //                         <Text fontSize={18} color="white">
    //                             2021
    //                         </Text>
    //                     </Pane>
    //                 </Pane>
    //             </Pane>
    //             <Pane
    //                 flexGrow={1}
    //                 marginLeft={16}
    //                 paddingX={16}
    //                 borderLeft="1px solid red"
    //             >
    //                 Lorem Ipsum is simply dummy text of the printing and
    //                 typesetting industry. Lorem Ipsum has been the industry's
    //                 standard dummy text ever since the 1500s, when an unknown
    //                 printer took a galley of type and scrambled it to make a
    //                 type specimen book. It has survived not only five centuries,
    //                 but also the leap into electronic typesetting, remaining
    //                 essentially unchanged.
    //             </Pane>
    //         </Pane>
    //         <Pane display="flex">footer</Pane>
    //         <Pane
    //             display="flex"
    //             flexDirection="column"
    //             alignItems="center"
    //             marginTop={33}
    //         >
    //             <Pane
    //                 display="flex"
    //                 alignItems="center"
    //                 justifyContent="space-between"
    //                 // borderBottom="1px solid #e8e8e8"
    //                 padding={8}
    //                 marginBottom={20}
    //                 position="relative"
    //             >
    //                 {/* <Text size={500} fontWeight={600}> */}
    //                 <Heading size={500} marginTop={5} color="#3279CB">
    //                     Publications
    //                 </Heading>
    //                 {/* </Text> */}
    //                 <div
    //                     style={{
    //                         position: "absolute",
    //                         bottom: 0,
    //                         right: 0,
    //                         width: "15%",
    //                         height: "33%",
    //                         borderBottom: "1px solid #3366FF",
    //                         borderRight: "1px solid #3279CB",
    //                     }}
    //                 />
    //             </Pane>

    //             {publications.map((publication, index) => (
    //                 <Pane
    //                     // flexDirection="row"
    //                     // paddingBottom={16}
    //                     // marginBottom={16}
    //                     key={index}
    //                     display="flex"
    //                     // alignItems="center"
    //                     marginBottom={2}
    //                     marginX="4%"
    //                     width="84%"
    //                     flexDirection="column"
    //                     justifyContent="center"
    //                     padding={8}
    //                     background="#F2F2F4"
    //                     // borderBottom="1px solid red"
    //                     overflow="hidden"
    //                     borderRadius={5}
    //                     // boxShadow="0px 1px 4px rgba(67, 90, 111, 0.3)"
    //                     cursor="pointer"
    //                     onClick={() => handlePublicationClick(index)}
    //                 >
    //                     {/* <Heading is="h5" color="navy">
    //                         Heading h3
    //                     </Heading> */}
    //                     <Strong
    //                         size={500}
    //                         fontSize={14}
    //                         marginTop={5}
    //                         marginBottom={5}
    //                         color="navy"
    //                     >
    //                         {publication.title}
    //                     </Strong>
    //                     <Pane marginBottom={8}>
    //                         <Text size={400} color="gray800">
    //                             Authors: {publication.authors}
    //                         </Text>
    //                     </Pane>
    //                     <Pane>
    //                         <Text size={400} color="gray800">
    //                             Publisher: {publication.publisher}
    //                         </Text>
    //                     </Pane>
    //                     <Pane
    //                         height={1}
    //                         width="99%"
    //                         borderBottom="1px dashed #a4a4a4"
    //                         marginY={0}
    //                     />
    //                     {expandedPublication === index && (
    //                         <Pane marginTop={16}>
    //                             <Text>{publication.abstract}</Text>
    //                         </Pane>
    //                     )}
    //                     {/* <Pane display="flex" justifyContent="flex-end" margin={5}> */}
    //                     <Pane textAlign="right" paddingTop={5}>
    //                         {renderBadge(publication.type)}
    //                     </Pane>
    //                 </Pane>
    //             ))}
    //         </Pane>
    //     </Pane>
    // );

    // return (
    //     <Pane
    //         display="flex"
    //         flexDirection="column"
    //         alignItems="center"
    //         marginTop={33}
    //     >
    //         <Pane
    //             display="flex"
    //             alignItems="center"
    //             justifyContent="space-between"
    //             // borderBottom="1px solid #e8e8e8"
    //             padding={8}
    //             marginBottom={20}
    //             position="relative"
    //         >
    //             {/* <Text size={500} fontWeight={600}> */}
    //             <Heading size={500} marginTop={5} color="#3279CB">
    //                 Pubs
    //             </Heading>
    //             {/* </Text> */}
    //             <div
    //                 style={{
    //                     position: "absolute",
    //                     bottom: 0,
    //                     right: 0,
    //                     width: "15%",
    //                     height: "33%",
    //                     borderBottom: "1px solid white",
    //                     borderRight: "1px solid white",
    //                 }}
    //             />
    //         </Pane>

    //         {publications.map((publication, index) => (
    //             <Pane
    //                 key={index}
    //                 display="flex"
    //                 // alignItems="center"
    //                 marginBottom={2}
    //                 marginX="4%"
    //                 width="84%"
    //                 flexDirection="column"
    //                 justifyContent="center"
    //                 padding={8}
    //                 background="#F2F2F4"
    //                 borderRadius={5}
    //                 boxShadow="0px 1px 4px rgba(67, 90, 111, 0.3)"
    //                 cursor="pointer"
    //                 onClick={() => handlePublicationClick(index)}
    //             >
    //                 <Strong
    //                     size={500}
    //                     fontSize={14}
    //                     marginTop={5}
    //                     marginBottom={5}
    //                     color="navy"
    //                 >
    //                     {publication.title}
    //                 </Strong>
    //                 <Pane marginBottom={8}>
    //                     <Text size={400} color="gray800">
    //                         Authors: {publication.authors}
    //                     </Text>
    //                 </Pane>
    //                 <Pane>
    //                     <Text size={400} color="gray800">
    //                         Publisher: {publication.publisher}
    //                     </Text>
    //                 </Pane>
    //                 <Pane
    //                     height={1}
    //                     width="99%"
    //                     borderBottom="1px dashed #a4a4a4"
    //                     marginY={0}
    //                 />
    //                 {/* <Pane display="flex" justifyContent="flex-end" margin={5}> */}
    //                 <Pane textAlign="right" paddingTop={5}>
    //                     {renderBadge(publication.type)}
    //                 </Pane>
    //                 {expandedPublication === index && (
    //                     <Pane marginTop={16}>
    //                         <Text>{publication.abstract}</Text>
    //                     </Pane>
    //                 )}
    //             </Pane>
    //         ))}
    //     </Pane>
    // );
};

export default PublicationList;
