import { Box, Grid, Link, Paper, Stack, Typography, Tooltip } from "@mui/material";
import {
    VscRemote,
    VscError,
    VscWarning,
    VscBell,
    VscFeedback,
    VscCheck,
} from "react-icons/vsc";
import { IoIosGitBranch } from "react-icons/io";

export default function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <Box
            component={Paper}
            square
            elevation={0}
            // sx={{ height: '3vh' }}
            sx={{ height: "20px", color: "white" }}
            display="flex"
        >
            <Grid container>
                <Grid
                    item
                    sx={{
                        width: "35px",
                        // backgroundColor: "#2E8461",
                        backgroundColor: "#21a12a",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        "&:hover": {
                            background: "#329171",
                        },
                    }}
                    display="flex"
                >
                    <VscRemote fontSize="1.0rem" />
                </Grid>
                <Grid
                    item
                    sx={{ backgroundColor: "#3279CB", width: "125px" }}
                    // sx={{ backgroundColor: "#3279CB", width: "330px" }}
                    // sx={{ backgroundColor: "#3279CB", width: "200px" }}
                    display="flex"
                >
                    <Stack direction="row" spacing={0.5} sx={{ pl: 1 }}>
                        {/* <Stack direction="row" spacing={0.5}> */}
                        {/* <Tooltip title="react-vscode-portoflio (Git)" arrow> */}
                        {/* <Link
                        href="https://github.com/noworneverev/react-vscode-portfolio"
                        underline="none"
                        color="white"
                        target="_blank"
                        > */}
                        <Tooltip title="Hmmm, you found an easter egg!" arrow placement="top">
                            <Box
                                component={Link}
                                // href="https://github.com/noworneverev/react-vscode-portfolio"
                                href="https://github.com/SrDNS"
                                underline="none"
                                color="white"
                                target="_blank"
                                display="flex"
                                sx={{
                                    px: .5,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    // cursor: "pointer",
                                    "&:hover": {
                                        background: "#1f8ad2",
                                    },
                                }}
                            >
                                <IoIosGitBranch fontSize="1.0rem" />
                                <Typography
                                    sx={{ ml: 0.5, mt: 0.4, fontSize: "0.7rem" }}
                                >
                                    main
                                </Typography>
                            </Box>
                        </Tooltip>
                        {/* </Link> */}

                        {/* </Tooltip> */}
                        {/* </Stack> */}
                        <Stack
                            direction="row"
                            spacing={0.5}
                            sx={{
                                px: 0.5,
                                cursor: "pointer",
                                "&:hover": {
                                    background: "#1f8ad2",
                                },
                            }}
                        >
                            <Box
                                display="flex"
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    py: 0.3,
                                }}
                            >
                                <VscError fontSize="0.9rem" />
                            </Box>
                            <Box
                                display="flex"
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    pt: 0.4,
                                }}
                            >
                                <Typography sx={{ fontSize: "0.7rem" }}>
                                    0
                                </Typography>
                            </Box>

                            <Box
                                display="flex"
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    py: 0.3,
                                }}
                            >
                                <VscWarning fontSize="0.9rem" />
                            </Box>
                            <Box
                                display="flex"
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    pt: 0.4,
                                }}
                            >
                                <Typography sx={{ fontSize: "0.7rem" }}>
                                    0
                                </Typography>
                            </Box>
                        </Stack>
                    </Stack>
                </Grid>
                {/* New container for my name */}
                <Grid
                    item
                    sx={{
                    backgroundColor: "#3279CB",
                    flexGrow: 1, // Fill available space
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                    {/* <Typography variant="caption" sx={{ color: "white" }}> */}
                    <Typography sx={{ ml: 0.0, mt: 0.1, fontSize: "0.8rem" }} >
                        © {currentYear} &#8226; C. A. Sardianos
                    </Typography>
                    </div>
                </Grid>
                {/* <Grid
                    item
                    sx={{
                        backgroundColor: "#3279CB",
                        width: "1150px",
                        //`calc(91% - 235px)`,
                        // flexGrow: 1,
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography variant="caption" sx={{ color: "white" }}>
                        © {currentYear} C. A. Sardianos
                    </Typography>
                </Grid> */}
                <Grid
                    item
                    // sx={{ backgroundColor: '#3279CB', minWidth: `calc(100% - 35px)` }}
                    // sx={{ backgroundColor: "#3279CB", minWidth: `calc(100% - 235px)` }}
                    sx={{ backgroundColor: "#3279CB" }}
                    // sx={{
                    //     backgroundColor: "#3279CB",
                    //     minWidth: `calc(100% - 1385px)`,
                    //     //"116px",
                    //     //`calc(100% - 335px)`,
                    // }}
                    display="flex"
                    justifyContent="flex-end"
                >
                    <Box display="flex" justifyContent="flex-end">
                        <Stack
                            // display="flex"
                            justifyContent="end"
                            direction="row"
                            spacing={0.8}
                            sx={{ pr: 1.5 }}
                        >
                            <Box
                                display="flex"
                                sx={{
                                    px: 0.5,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    "&:hover": {
                                        background: "#1f8ad2",
                                    },
                                }}
                            >
                                <VscCheck fontSize="0.9rem" />
                                <Typography
                                    sx={{
                                        ml: 0.5,
                                        mt: 0.4,
                                        fontSize: "0.7rem",
                                    }}
                                >
                                    Prettier
                                </Typography>
                            </Box>

                            <Box
                                display="flex"
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    py: 0.3,
                                    px: 0.5,
                                    cursor: "pointer",
                                    "&:hover": {
                                        background: "#1f8ad2",
                                    },
                                }}
                            >
                                <VscFeedback fontSize="0.9rem" />
                            </Box>
                            <Box
                                display="flex"
                                sx={{
                                    width: "50%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    py: 0.3,
                                    px: 0.5,
                                    cursor: "pointer",
                                    "&:hover": {
                                        background: "#1f8ad2",
                                    },
                                }}
                            >
                                <VscBell fontSize="0.9rem" />
                            </Box>
                        </Stack>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}
